/**
 * Created by Mikhail Menshenin on 27.08.2024
 */

RewardTypes.shields = {
    controls: "shields",
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return bundles.reward_icons.frames.reward_shield_png;
    },

    handler: function (value, options) {
        var shields = cleverapps.shields.get() + value;
        cleverapps.shields.set(shields, true);

        return function () {
            cleverapps.shields.change();
        };
    }
};