/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var HutView = cc.Node.extend({
    avoidNode: "HutView",

    ctor: function (hut) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.HutView;
        this.hut = hut;

        var image = this.image = new cc.Sprite(bundles[hut.village.getBundleId()].frames[hut.getFrameId(1)]);
        image.setPositionRound(styles.image);
        this.addChild(image);
        image.setVisible(false);

        this.animateLevelUp(true);
        this.checkUpgradeable();

        this.onResize();

        cleverapps.UI.onClick(this, this.createListener(this.hut.onClick.bind(this.hut)), { interactiveScale: true });

        hut.on("changeLevel", this.createListener(this.animateLevelUp.bind(this)));
        hut.on("attacked", this.createListener(this.attackAnimation.bind(this)));
        hut.on("changeUpgradeable", this.createListener(this.checkUpgradeable.bind(this)));

        if (cleverapps.config.debugMode) {
            hut.on("debugBorder", this.createListener(this.debugBorder.bind(this)));
            hut.on("cleanupBorder", this.createListener(this.cleanupBorder.bind(this)));
        }
    },

    createIcon: function () {
        var styles = cleverapps.styles.HutView;
        if (this.hut.canBuild()) {
            var icon = new cc.Sprite(bundles.village.frames.build_png);
            icon.setPositionRound(styles.icon);
            icon.setScale2(styles.icon.scale);
            this.addChild(icon);
            this.icon = new HidingNode(icon, HidingNode.DIR.NONE);
            cleverapps.focusManager.registerControl("buildIcon" + this.hut.id, this.icon);
            this.icon.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this));
        }

        if (this.hut.canAttack()) {
            targetIcon = new HutScopeView(this.hut);
            targetIcon.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
            this.addChild(targetIcon);
            var control = new HidingNode(targetIcon);
            cleverapps.focusManager.registerControl("villageScope" + this.hut.id, control);
            control.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this));
        }
    },

    updateSize: function () {
        if (this.wideMode !== cleverapps.resolution.mode) {
            this.wideMode = cleverapps.resolution.mode;

            this.setImageSize();
            this.updatePosition();
        }
    },

    updatePosition: function () {
        var styles = cleverapps.styles.HutView;
        var positions = styles.villages[this.hut.village.level] || styles.positions;
        var stylesPosition = styles.positions[this.hut.id];
        var position = positions[this.hut.id].map(function (pos, i) {
            return pos.x !== undefined && pos.y !== undefined ? pos : stylesPosition[i]
        });
        this.setPositionRound(position);
    },

    animateLevelUp: function (silent) {
        var newFrame = bundles[this.hut.village.getBundleId()].frames[this.hut.getFrameId()];

        if (silent && this.hut.isVisible()) {
            this.setFrame(newFrame);
            this.image.setVisible(true);
            if (this.icon) {
                this.icon.toggle(false, silent);
            }
            return;
        }

        if (!this.hut.isVisible()) {
            this.image.setVisible(false);
            return;
        }

        var oldImage = this.hut.isVisible() ? this.image : this.mockImage();
        var styles = cleverapps.styles.HutView;
        var newImage = new cc.Sprite(newFrame);
        newImage.setPositionRound(styles.image);
        this.addChild(newImage);
        this.image = newImage;

        this.icon.toggle(false);
        AnimationsLibrary.upgrade(oldImage, newImage, {
            cloud: true,
            parent: cleverapps.scenes.getMovingNode(this),
            callback: function () {
                this.setFrame(newFrame);
                this.icon.toggle(!this.hut.isComplete());
            }.bind(this)
        });
    },

    mockImage: function () {
        var node = new cc.Node();
        node.setContentSize2(this.image.getContentSize());
        node.setPosition(this.image.getPosition());
        this.addChild(node);
        return node;
    },

    attackAnimation: function (cb) {
        var newFrame = bundles[this.hut.village.getBundleId()].frames[this.hut.getFrameId()];

        var oldImage = this.hut.isVisible() ? this.image : this.mockImage();
        var styles = cleverapps.styles.HutView;
        var newImage = new cc.Sprite(newFrame);
        newImage.setPositionRound(styles.image);
        this.addChild(newImage);
        this.image = newImage;
        AnimationsLibrary.upgrade(oldImage, newImage, {
            callback: function () {
                this.setFrame(newFrame);
                cb();
            }.bind(this)
        });

        var styles = cleverapps.styles.HutView;
        var spine = new cleverapps.Spine(bundles.village.jsons.small_lightning_json);
        spine.setPositionRound(styles.lightning);
        this.addChild(spine);
        spine.setAnimation(0, "animation", false);
        spine.setCompleteListenerRemove();

        var smoke = new cleverapps.Spine(bundles.village.jsons.smoke_json);
        smoke.setPositionRound(styles.smoke);
        smoke.setLocalZOrder(-1);
        this.addChild(smoke);
        smoke.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.2),
            new cc.Show(),
            new cc.CallFunc(function () {
                smoke.setAnimation(0, "smoke", true);
            })
        ));
    },

    setFrame: function (frame) {
        this.image.setSpriteFrame(frame);
        this.setImageSize();
    },

    setImageSize: function () {
        this.setContentSize2(this.image.getContentSize());
    },

    checkUpgradeable: function () {
        if (this.icon) {
            if (this.hut.upgradeable) {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_png);
            } else {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_grey_png);
            }
            this.icon.toggle(!this.hut.isComplete(), true);
        }
    },

    spawn: function () {
        this.runAction(
            AnimationsLibrary.spawn(this, {
                delay: 1 + this.hut.id * 0.2,
                duration: 0.2
            })
        );
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HUT_LEVEL_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIFE_GREEN
    },
    HUT_DAMAGE_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.COLOR_RED
    }
});

cleverapps.styles.HutView = {
    width: [300, 390, 420],
    height: [300, 252, 252],

    icon: {
        x: { align: "center" },
        y: { align: "bottom" },
        scale: [0.73, 0.57, 0.57],
    },

    villages: [],
    positions: [
        [{
            x: { align: "center", dx: -100 },
            y: { align: "center", dy: 500 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 400 }
        }, {
            x: { align: "center", dx: -290 },
            y: { align: "center", dy: 280 }
        }, {
            x: { align: "center", dx: -310 },
            y: { align: "center", dy: 280 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 290 },
            y: { align: "center", dy: 280 }
        }, {
            x: { align: "center", dx: 310 },
            y: { align: "center", dy: 280 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: -420 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: -450 },
            y: { align: "center", dy: 0 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: -200 }
        }, {
            x: { align: "center", dx: 420 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: 450 },
            y: { align: "center", dy: 0 }
        }]
    ],

    attention: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 },

        scale: 0.5
    },

    image: {
        x: { align: "center" },
        y: { align: "center" }
    },

    lightning: {
        x: { align: "center" },
        y: { align: "center" }
    },

    smoke: {
        x: { align: "center" },
        y: { align: "center" }
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat("HutView");