/**
 * Created by olga on 06.09.2024
 */

Transition.prototype.getType = cleverapps.extendFunc(Transition.prototype.getType, function () {
    var type = this._super.apply(this, arguments);
    if (this.toScene.transitionType) {
        type = this.toScene.transitionType;
    }
    return type;
});

Transition.prototype.createContent = cleverapps.extendFunc(Transition.prototype.createContent, function () {
    this._super.apply(this, arguments);
    if (this.type === Transition.TYPE_ATTACK) {
        this.background = new cleverapps.Spine(bundles.transition_attack.jsons.attack_transition_bg_json);
        this.background.setPositionRound({ align: "center" }, { align: "center" });
        this.addChild(this.background);

        this.content = new cleverapps.Spine(bundles.transition_attack.jsons.attack_transition_json);
        this.content.setPositionRound({ align: "center" }, { align: "center" });
        this.addChild(this.content);

        this.bundles = ["transition_attack"];
    }

    if (this.type === Transition.TYPE_RAID) {
        this.background = new cleverapps.Spine(bundles.transition_raid.jsons.raid_transition_json);
        this.background.setPositionRound({ align: "center" }, { align: "center" });
        this.addChild(this.background);

        this.bundles = ["transition_raid"];
    }
});

Transition.prototype.show = cleverapps.extendFunc(Transition.prototype.show, function () {
    if (this.type === Transition.TYPE_ATTACK) {
        this.attackShow.apply(this, arguments);
    } else if (this.type === Transition.TYPE_RAID) {
        this.raidShow.apply(this, arguments);
    } else {
        this._super.apply(this, arguments);
    }
});

Transition.prototype.hide = cleverapps.extendFunc(Transition.prototype.hide, function () {
    if (this.type === Transition.TYPE_ATTACK) {
        this.attackHide.apply(this, arguments);
    } else if (this.type === Transition.TYPE_RAID) {
        this.raidHide.apply(this, arguments);
    } else {
        this._super.apply(this, arguments);
    }
});

Transition.prototype.attackShow = function (f) {
    this.content.setAnimationAndIdleAfter("open", "idle");

    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.4),
        new cc.CallFunc(function () {
            this.background.setAnimation(0, "open", false);
            this.background.setCompleteListener(f);
        }.bind(this))
    ))

    this.setVisible(true);
};

Transition.prototype.attackHide = function (f) {
    this.content.setCompleteListener(function () {
        this.toScene.scaleUpAnimation();
        this.background.setAnimation(0, "close", false);
        this.content.setAnimationAndIdleAfter("close", "glow");
        var duration = this.content.getAnimationData("close").duration;
        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.MoveBy(duration - 0.3, 0, this.content.height - this.toScene.height / 2)
        ));
        this.content.setCompleteListener(function () {
            this.content.replaceParentSamePlace(this.toScene);
            this.toScene.hammer = this.content;
            this.content.setPositionRound(this.content.getPosition());
            this.setVisible(false);
            f();
        }.bind(this));
    }.bind(this));
}

Transition.prototype.raidShow = function (f) {
    this.showRaidPanel(function () {
        this.background.setAnimation(0, "open", false);
        this.background.setCompleteListener(f);
        this.setVisible(true);
    }.bind(this));
};

Transition.prototype.raidHide = function (f) {
    this.background.setAnimation(0, "close", false);
    this.background.setCompleteListener(function () {
        this.setVisible(false);
        f();
    }.bind(this));
};

Transition.prototype.showRaidPanel = function (f) {
    var scene = cleverapps.scenes.getRunningScene();
    var village = aisensia.villageManager.getRaidVillage();
    var controlPanel = new RaidControlPanelView(village, true);
    controlPanel.setPositionRound(cleverapps.styles.VillageRaidScene.control);
    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
        cleverapps.UI.fitToBox(controlPanel, cleverapps.styles.VillageRaidScene.control);
    }
    scene.addChild(controlPanel);
    scene.slotMachineView.coinMaster.animateRaidPlayer(controlPanel.avatar);
    controlPanel.showSlotMachine(f);
};


Transition.TYPE_ATTACK = "attack";
Transition.TYPE_RAID = "raid";

cleverapps.styles.Transition[Transition.TYPE_ATTACK] = {
    scale: 1
};

cleverapps.styles.Transition[Transition.TYPE_RAID] = {
    scale: 1
};
