/**
 * Created by Mikhail Menshenin on 19.08.2024
 */

var ShieldsView = cc.Node.extend({
    ctor: function (item) {
        var styles = cleverapps.styles.ShieldsView;
        this._super();

        this.item = item;

        this.setAnchorPoint(0.5, 0.5);
        this.setScale2(styles.scale);

        var animation = this.animation = new cleverapps.Spine(bundles.shields.jsons.shield_animation);
        animation.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        animation.setAnimation(0, "animation_00", false);
        this.setContentSize2(styles.size);
        this.addChild(animation);

        styles.targets.forEach(function (position, index) {
            var num = index + 1;
            var target = new cc.Node();
            target.setContentSize(styles.target);
            target.setPositionRound(position);
            this.addChild(target);
            cleverapps.aims.registerTarget("shield" + num, target, {
                controls: "shields",
                noTargetDelta: true
            });
        }.bind(this));

        this.updateShields(true);
        cleverapps.shields.on("change", this.createListener(this.updateShields.bind(this)));
    },

    updateShields: function (silent) {
        var frame = cleverapps.shields.exceeded && !silent ? "animation_04" :
            "animation_0" + cleverapps.shields.get();
        this.animation.setAnimation(0, frame, false);
    }
});

cleverapps.styles.ShieldsView = {
    scale: [1.5, 1, 1],
    size: {
        width: 200,
        height: 85
    },
    target: {
        width: 74,
        height: 80
    },
    targets: [{
        x: { align: "center", dx: -38 },
        y: { align: "center" }
    }, {
        x: { align: "center", dx: 0 },
        y: { align: "center" }
    }, {
        x: { align: "center", dx: 37 },
        y: { align: "center" }
    }]
};