/**
 * Created by Mikhail Menshenin on 24.10.2024
 */

var Kingdom = function (locationId) {
    cleverapps.EventEmitter.call(this);
    cleverapps.kingdom = this;

    var slot = cleverapps.meta.getSlotId(locationId);
    var data = cleverapps.meta.loadLocation(slot);
    this.load(data);
};

Kingdom.prototype = Object.create(cleverapps.EventEmitter.prototype);
Kingdom.prototype.constructor = Kingdom;

Kingdom.prototype.load = function (data) {
    data = data || {};
    this.data = data;
};

Kingdom.prototype.save = function (data) {
    this.data = data;
    cleverapps.meta.saveLocation(this);
};

Kingdom.prototype.amountLevelStars = function (level) {
    return level.isHard() ? 3 : 1;
};

Kingdom.prototype.getProgress = function () {
    var village = aisensia.villageManager.getVillage();
    return {
        current: village.getProgress(),
        goal: village.getGoal()
    };
};

Kingdom.prototype.gamePlayed = function (outcome, game) {
    throw new Error("SlotMachine game never ends. Are you sure about game played?");
};

Kingdom.prototype.getConfig = function () {
    return this.config;
};

Kingdom.prototype.getInfo = function () {
    return this.data || {};
};

Kingdom.prototype.processChatMessage = function (message) {
    console.warn("chat message processing doesn't implemented");
};

Kingdom.prototype.onOpen = function (f) {
    cleverapps.scenes.replaceScene(new VillageScene(), f);
};

Kingdom.listLocationsIds = function () {
    var ids = [];

    for (var level = 0; bundles["village_" + level]; level++) {
        ids.push(level);
    }

    return ids;
};

Kingdom.listActiveLocationsIds = function (currentLocationId) {
    return [currentLocationId];
};