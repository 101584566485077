/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var VillageScene = cleverapps.FixedWidthScene.extend({
    ctor: function () {
        this._super();

        this.village = this.getVillage();
    },

    onSceneLoaded: function (sceneName) {
        this._super(sceneName || cleverapps.Environment.SCENE_MAIN);

        cleverapps.environment.levelNo = levels.user.level;
        cleverapps.environment.episodeNo = levels.user.episode;

        cleverapps.placements.run(Placements.INTERMEDIATE);

        this.addVillage();

        this.addUI();

        this.upMenuContainer.avoidNode = "village";
        this.addAvoidNode(this.upMenuContainer);

        this.updateSize();

        if (cleverapps.config.debugMode) {
            cleverapps.git.changeFolders(GitManager.FOLDERS.VILLAGES);
        }

        if (aisensia.savedVillage) {
            aisensia.savedVillage = undefined;
        }

        var exclamationView = this.exclamationView = ExclamationViewBase.Create();
        this.addChild(exclamationView, 30);
        exclamationView.setPositionRound(cleverapps.styles.VillageScene.exclamation);
    },

    addVillage: function () {
        var village = this.villageView = new VillageView(this.village);
        this.background.addChild(village);
        village.createIcons();

        var control = new HidingNode(village, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("village", control);

        return control;
    },

    addUI: function () {
        this.addProgress();

        var styles = cleverapps.styles.VillageScene;

        var hammer = new VillageUpgradeToolbarItemView(new VillageUpgradeToolbarItem(this.village));
        hammer.avoidNode = "hammer";
        hammer.setPositionRound(styles.hammer.position);
        this.addChild(hammer);
        cleverapps.focusManager.registerControl("hammer", new HidingNode(hammer, HidingNode.DIR.NONE));

        this.title = new VillageTitleView(this.village);
        this.title.setPositionRound(styles.title.position);
        this.addChild(this.title);
        cleverapps.focusManager.registerControl("title", new HidingNode(this.title, HidingNode.DIR.NONE));
    },

    addProgress: function () {
        var styles = cleverapps.styles.VillageScene;
        var progress = new VillageProgressView(this.village);
        progress.setPositionRound(styles.progress.position);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            progress.setScale(0.8);
        }
        this.addChild(progress);

        var control = new HidingNode(progress, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("progress", control);

        return control;
    },

    getBackgroundStyles: function () {
        var bundleName = aisensia.villageManager.getBackgroundBundle(this.village.level);
        return {
            bundle: bundleName,
            backgroundId: "background"
        };
    },

    getVillage: function () {
        if (aisensia && aisensia.villageManager) {
            return aisensia.villageManager.getVillage();
        }
    },

    isInCurrentLocation: function () {
        return this.village.isStateBuild();
    },

    listBundles: function () {
        var bundles = ["village"];

        if (this.village) {
            bundles.push(aisensia.villageManager.getBackgroundBundle(this.village.level));
            bundles.push(this.village.getBundleId());
        }

        return bundles;
    },

    _closeAction: MainScene.prototype._closeAction
});

HomefixScene = VillageScene;

cleverapps.styles.Exclamations.base = {
    width: 800,
    height: 250
};

cleverapps.styles.VillageScene = {
    exclamation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    title: {
        height: 80,
        padding: {
            x: 60
        },
        position: [{
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 280 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 265 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 265 }
        }]
    },

    hammer: {
        position: [{
            x: { align: "left", dx: 31 },
            y: { align: "bottom", dy: 20 }
        }, {
            x: { align: "left", dx: 103 },
            y: { align: "bottom", dy: 34 }
        }, {
            x: { align: "left", dx: 103 },
            y: { align: "bottom", dy: 34 }
        }]
    },

    progress: {
        position: [
            {
                x: { align: "center", dx: 10 },
                y: { align: "bottom", dy: 180 }
            }, {
                x: { align: "center", dx: 25 },
                y: { align: "bottom", dy: 140 }
            }, {
                x: { align: "center", dx: 25 },
                y: { align: "bottom", dy: 140 }
            }
        ]
    },

    shields: {
        position: [
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -180 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 0 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 0 }
            }
        ]
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat(["hammer", "VillageTitle", "village"]);