/**
 * Created by Slava on 14.10.2024
 */

LevelMasteryView.prototype.ctor = cleverapps.extendFunc(LevelMasteryView.prototype.ctor, function (options) {
    options = options || {};
    var styles = cleverapps.styles.LevelMasteryView;
    var type = this.type = options.type || "window";
    styles = this.styles = Object.assign({}, styles.Type[type], styles);
    var maxLevel = aisensia.levelMastery.getMaxLevel();
    var reward = aisensia.levelMastery.getCurrentReward();
    this.level = aisensia.levelMastery.level;

    cc.Node.prototype.ctor.call(this);
    this.setAnchorPoint(0.5, 0.5);

    if (type === "window") {
        var rewardIcon = this.rewardIcon = new cc.Sprite(bundles.levelmastery.frames.bg_green_large_2);
        rewardIcon.setPositionRound(styles.reward);
        this.addChild(rewardIcon);
        cleverapps.UI.fitToBox(rewardIcon, styles.reward);
    }

    var progressBar = this.progressBar = new ScaledProgressBar({
        type: {
            progress: bundles.progress_bar.frames.bar_green_large,
            background: bundles.levelmastery.frames.bg_green_large_levelmastery
        },
        barText: {
            dy: 2,
            font: cleverapps.styles.FONTS.STARS_PROGRESS_BAR_TEXT
        }
    });
    progressBar.setLength(styles.width);
    this.setContentSize(this.progressBar.getContentSize());
    progressBar.setGoal(maxLevel);
    progressBar.setPercentage(type === "victory" ? this.level - 1 : this.level);
    progressBar.setPositionRound(styles.position);
    this.addChild(progressBar);

    var cupIcon = this.cupIcon = this.createCupIcon(styles.cup);
    cupIcon.setPositionRound(styles.cup);
    this.addChild(cupIcon);

    if (type === "window") {
        var cupCircle = new cc.Sprite(bundles.levelmastery.frames.bg_green_large_circle);
        cupCircle.setAnchorPoint(0.5, 0.5);
        cupCircle.setPositionRound(styles.cupCircle);
        cleverapps.UI.fitToBox(this.cupIcon, styles.cup);
        this.cupIcon.addChild(cupCircle, -1);

        var prizeLayout = this.createPrizeIcon();
        prizeLayout.setPositionRound(styles.reward_icons);
        this.addChild(prizeLayout);
    }

    if (type === "scene") {
        var timer = this.createTimer(styles.timer);
        this.addChild(timer);
    }

    this.setContentSize(cleverapps.UI.calculateContentSize(this));

    cleverapps.aims.registerTarget("levelMastery", this, {
        controls: "levelmastery_progress",
        withoutDelta: true,
        collectAnimate: this.collectAnimate.bind(this)
    });

    aisensia.levelMastery.on("updateStage", this.updateStage.bind(this), this);
    aisensia.levelMastery.on("updateLevel", this.updateLevel.bind(this), this);
});

LevelMasteryView.prototype.createPrizeIcon = function () {
    var styles = cleverapps.styles.LevelMasteryView;

    var iconFirst = new cc.Sprite(bundles.slotmachine.frames.energy_png);
    iconFirst.setAnchorPoint(0.5, 0.5);
    cleverapps.UI.fitToBox(iconFirst, styles.reward_icons.first);

    var firstText = cleverapps.UI.generateImageText(900, cleverapps.styles.FONTS.LEVELMASTERY_PROGRESS_REWARD_TEXT);
    firstText.setPositionRound(styles.reward_icons.first.text);
    iconFirst.addChild(firstText);

    var iconSecond = new cc.Sprite(bundles.slotmachine.frames.energy_png);
    iconSecond.setAnchorPoint(0.5, 0.5);
    cleverapps.UI.fitToBox(iconSecond, styles.reward_icons.second);

    var secondText = cleverapps.UI.generateImageText(250, cleverapps.styles.FONTS.LEVELMASTERY_PROGRESS_REWARD_TEXT);
    secondText.setPositionRound(styles.reward_icons.second.text);
    iconSecond.addChild(secondText);

    var layout = new cleverapps.Layout([iconFirst, iconSecond], {
        direction: cleverapps.UI.HORIZONTAL,
        margin: styles.reward_icons.margin
    });

    return layout;
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELMASTERY_PROGRESS_REWARD_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelMasteryView, {
    reward: {
        x: { align: "right", dx: 45 },
        y: { align: "center", dy: 5 },
        width: 175,
        height: 85
    },

    reward_icons: {
        x: { align: "right", dx: 55 },
        y: { align: "center", dy: 13 },

        margin: 40,

        first: {
            width: 54,
            height: 73,
            text: {
                x: { align: "center" },
                y: { align: "center", dy: -30 }
            }
        },

        second: {
            width: 54,
            height: 73,
            text: {
                x: { align: "center" },
                y: { align: "center", dy: -30 }
            }
        }
    },

    position: {
        x: { align: "center", dx: -30 },
        y: { align: "center" }
    },

    cupCircle: {
        x: { align: "center" },
        y: { align: "center", dy: 10 },
    },

    cup: {
        x: { align: "left", dx: -37 },
        y: { align: "center", dy: 2 },
        width: 115,
        height: 120
    },

    Type: {
        window: {
            width: 525
        }
    },
});