/**
 * Created by Mikhail Menshenin on 17.10.2024
 */

var HutScopeView = cc.Node.extend({
    ctor: function (hut) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.hut = hut;

        var icon = this.icon = new cc.Sprite(bundles.village.frames.target_png);
        this.setContentSize2(icon.getContentSize());
        icon.setPosition(this.width / 2, this.height / 2);
        this.addChild(icon);
    },

    showAnimation: function (silent) {
        if (this.hut.village.canAttack() && this.hut.canAttack()) {
            this.setVisible(true);
        } else {
            this.setVisible(false);
            return;
        }

        if (silent) {
            this.icon.setScale(1);
            return;
        }

        this.icon.setScale(0);
        this.icon.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.7 + 0.2 * this.hut.id),
            new cc.Show(),
            cleverapps.UI.animateScale({
                duration: 0.1,
                scale: 1,
                overScaling: 1.2
            })
        ));
    },

    hideAnimation: function (silent) {
        if (silent) {
            this.icon.setScale(0);
            return;
        }

        this.icon.setScale(1);
        this.icon.runAction(new cc.Sequence(
            new cc.DelayTime(this.hut.attacked ? 0.1 : 0),
            cleverapps.UI.animateScale({
                duration: this.hut.attacked ? 0.1 : 0.2,
                scale: 0
            }),
            new cc.Hide()
        ));
    }
});
