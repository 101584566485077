/**
 * Created by Slava on 21.10.2024
 */

ExclamationViewBase.prototype.showMessage = cleverapps.extendFunc(ExclamationViewBase.prototype.showMessage, function (message, type, callback) {
    if (type === Exclamation.Types.Firework) {
        this.runActions(message, cleverapps.styles.FONTS.RAID_EXCLAMATION_TEXT, undefined, "firework", callback);
        return;
    }
    if (type === Exclamation.Types.No_firework) {
        this.runActions(message, cleverapps.styles.FONTS.RAID_EXCLAMATION_TEXT, undefined, "no_firework", callback);
        return;
    }

    this._super(message, type, callback);
});

Exclamation.Types = {
    Firework: 103,
    No_firework: 104
};