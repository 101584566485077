/**
 * Created by Mikhail Menshenin on 17.10.2024
 */

var AttackResultPanelView = cc.Node.extend({
    ctor: function (village) {
        this._super();

        var styles = cleverapps.styles.AttackResultPanelView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var controlPanelBg = cleverapps.UI.createScale9Sprite(bundles.village.frames.control_panel_bg);
        controlPanelBg.setContentSize(styles);
        controlPanelBg.setPosition(this.width / 2, this.height / 2);
        this.addChild(controlPanelBg);

        var player = village.player;
        var textLine = cleverapps.UI.generateOnlyText(village.protected ? "%{name} blocked your" : "You attack %{name}'s", cleverapps.styles.FONTS.DARK_TEXT, { name: cleverapps.UI.cutPlayerName(player.name, 8) });
        textLine.setPositionRound(styles.textLine);
        this.addChild(textLine);

        var textLine2 = cleverapps.UI.generateOnlyText(village.protected ? "attack. You won:" : "village and won:", cleverapps.styles.FONTS.DARK_TEXT);
        textLine2.setPositionRound(styles.textLine2);
        this.addChild(textLine2);

        var goldText = this.goldText = new TextWithIcon(aisensia.getNumberWithCommas(village.goldCollected) + " " + TextWithIcon.ICONS_BY_NAME.soft, styles.gold.options);
        goldText.setPositionRound(styles.gold);
        this.addChild(goldText);
    },

    show: function () {
        var position = this.getPosition();
        this.setPosition(position.x, position.y - this.height);
        cleverapps.audio.playSound(bundles.main.urls.control_panel_bg);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.MoveBy(0.4, 0, this.height).easing(cc.easeOut(1))
        ));
    }
});

cleverapps.styles.AttackResultPanelView = {
    width: 766,
    height: 220,

    textLine: {
        x: { align: "left", dx: 40 },
        y: { align: "bottom", dy: 124 }
    },

    textLine2: {
        x: { align: "left", dx: 40 },
        y: { align: "bottom", dy: 58 }
    },

    gold: {
        x: { align: "left", dx: 480 },
        y: { align: "bottom", dy: 54 }
    }
};