/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var Village = function (config, player) {
    cleverapps.EventEmitter.call(this);

    this.gold = 0;
    this.attention = 0;
    this.level = config.level;
    this.state = Village.STATE_BUILD;
    this.progress = 0;
    this.cost = Village.calculateVillageTotalCost(this.level);

    this.player = player || this.getCurrentPlayer();
    this.name = config.name;
    this.huts = config.huts ? config.huts.map(function (hutData, index) {
        return new Hut(this, hutData, index);
    }, this) : [];

    this.crosses = cleverapps.rangeArray(0, 3).map(function (index) {
        return new Cross(this, index);
    }, this);

    this.checkAttention();
    this.huts.forEach(function (hut) {
        hut.on("changeUpgradeable", this.checkAttention.bind(this));
    }.bind(this));

    if (cleverapps.config.debugMode) {
        this.checkHutExists();
    }
};

Village.prototype = Object.create(cleverapps.EventEmitter.prototype);
Village.prototype.constructor = Village;

Village.prototype.getBundleId = function () {
    return "village_" + this.level;
};

Village.prototype.openUpgradeWindow = function () {
    cleverapps.focusManager.display({
        focus: "VillageUpgradeWindow",
        control: ["MenuBarCoinsItem"],
        action: function (f) {
            new VillageUpgradeWindow(this);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    });
};

Village.prototype.getInfo = function () {
    var huts = this.huts.reduce(function (huts, hut) {
        var info = hut.getInfo();
        if (info) {
            huts[hut.name] = info;
        }
        return huts;
    }, {});

    var crosses = this.crosses.map(function (cross) {
        return cross.getInfo();
    }, {});

    return {
        huts: huts,
        crosses: crosses,
        raids: this.raids,
        gold: this.gold,
        goldCollected: this.goldCollected
    };
};

Village.prototype.getState = function () {
    return this.state
}

Village.prototype.save = function () {
    aisensia.villageManager.save(this);
};

Village.prototype.load = function (data) {
    if (!data) {
        return;
    }

    this.gold = data.gold || this.gold;
    this.goldCollected = data.goldCollected || this.goldCollected;
    this.state = data.state || this.state;

    var hutNames = data.huts && Object.keys(data.huts) || [];
    hutNames.forEach(function (hutName) {
        var hutData = data.huts[hutName];
        var hut = this.huts.find(function (hut) {
            return hut.name === hutName;
        });
        if (hut) {
            hut.load(hutData);
        }
    }.bind(this));

    var crosses = data.crosses || [];
    crosses.forEach(function (cross, index) {
        this.crosses[index].raided = cross.raided;
    }.bind(this));

    this.raids = data.raids || this.raids;
    this.progress = this.getProgress();
};

Village.prototype.getCurrentPlayer = function () {
    var playerInfo = cleverapps.user.getPlayerInfo();

    return {
        player: true,
        id: connector.platform.getUserID(),
        name: playerInfo.name,
        avatar: playerInfo.avatar
    };
};

Village.prototype.prepareToAttack = function (options) {
    this.state = Village.STATE_ATTACK;
    this.attacked = false;
    this.protected = false;
    this.counter = new Counter();

    this.huts.forEach(function (hut) {
        hut.attacked = false;
    });
};

Village.prototype.prepareToRaid = function (options) {
    this.state = Village.STATE_RAID;
    this.raids = options && options.raids || Village.MAX_RAIDS;
    this.goldCollected = options && options.goldCollected || 0;
    var smallGold = Math.floor(this.gold / 1000 / 6) * 1000;
    var bigGold = this.gold - 2 * smallGold;
    var crossesGold = cleverapps.Random.shuffle([0, smallGold, smallGold, bigGold]);
    this.perfectRaid = true;
    this.counter = new Counter();

    this.crosses.forEach(function (cross, index) {
        cross.raided = options && options.crosses[index].raided || false;
        cross.gold = crossesGold[index];
    });
};

Village.prototype.getProgress = function () {
    return this.huts.reduce(function (progress, hut) {
        return progress + hut.getCurrentLevel();
    }, 0);
};

Village.prototype.getGoal = function () {
    return this.huts.reduce(function (goal, hut) {
        return goal + hut.getMaxLevel();
    }, 0);
};

Village.prototype.checkAttention = function () {
    var attention = this.huts.reduce(function (attention, hut) {
        return attention + (hut.upgradeable ? 1 : 0);
    }, 0);

    if (this.attention !== attention) {
        this.attention = attention;
        this.trigger("changeAttention");
    }
};

Village.prototype.attack = function (cb) {
    cb = cb || function (f) {
        f();
    };
    this.attacked = true;
    var villageLevel = aisensia.villageManager.getVillageLevel();
    villageLevel = villageLevel >= Village.GOLD_BY_ATTACK.length ? (Village.GOLD_BY_ATTACK.length - 1) : villageLevel;
    this.goldCollected = Village.GOLD_BY_ATTACK[villageLevel];
    this.counter.inc();
    this.trigger("attacked", function (f) {
        cb(function () {
            this.counter.dec();
            f();
        }.bind(this));
    }.bind(this));
};

Village.prototype.protect = function () {
    this.attacked = true;
    var villageLevel = aisensia.villageManager.getVillageLevel();
    villageLevel = villageLevel >= Village.GOLD_BY_DEFEND.length ? (Village.GOLD_BY_DEFEND.length - 1) : villageLevel;
    this.goldCollected = Village.GOLD_BY_DEFEND[villageLevel];
    this.protected = true;
    this.counter.inc();
    this.trigger("protected", function (f) {
        this.counter.dec();
        f();
    }.bind(this));
};

Village.prototype.isAttacked = function () {
    return this.attacked;
};

Village.prototype.raid = function () {
    if (!this.isStateRaid()) {
        return;
    }
    this.counter.inc();
    this.raids--;
    this.trigger("changeRaids");
};

Village.prototype.raided = function (gold) {
    if (!this.isStateRaid()) {
        return;
    }

    if (gold === 0) {
        this.perfectRaid = false;
    }

    this.trigger("animateGoldRaided", gold, function () {
        this.goldCollected += gold;

        this.counter.dec();
    }.bind(this));
};

Village.prototype.updateProgress = function () {
    var progress = this.getProgress();

    if (this.progress !== progress) {
        this.progress = progress;

        this.save();

        this.trigger("changeProgress", this.checkGoal.bind(this));
    }
};

Village.prototype.checkGoal = function () {
    var goal = this.getGoal();

    if (this.progress >= goal) {
        aisensia.villageManager.moveNext();
    }
};

Village.prototype.isRaidFinished = function () {
    return !this.raids;
};

Village.prototype.isStateBuild = function () {
    return this.state === Village.STATE_BUILD;
}

Village.prototype.isStateAttack = function () {
    return this.state === Village.STATE_ATTACK;
}

Village.prototype.isStateRaid = function () {
    return this.state === Village.STATE_RAID;
}

Village.prototype.checkHutExists = function () {
    var bundle = bundles[this.getBundleId()];
    var existHuts = this.huts.filter(function (hut) {
        var frame = Object.keys(bundle.frames).find(function (frame) {
            return frame.indexOf(hut.name) === 0;
        });
        return !!frame;
    }).map(function (hut, index) {
        hut.id = index;
        return hut;
    });
    var existFrames = cleverapps.unique(Object.keys(bundle.frames).map(function (frame) {
        return frame.slice(0, frame.lastIndexOf("_"));
    }).filter(function (hutName) {
        var hut = existHuts.find(function (hut) {
            return hut.name == hutName;
        });
        return !hut;
    }));
    this.huts = existHuts.concat(existFrames.map(function (name, index) {
        return new Hut(this, { name: name }, existHuts.length + index);
    }.bind(this)));
};

Village.prototype.updatePlayer = function () {
    this.trigger("updatePlayer");
};

Village.prototype.canAttack = function () {
    return this.isStateAttack() && !this.attacked;
};

Village.prototype.canRaid = function () {
    return this.isStateRaid() && this.raids > 0;
};

Village.prototype.receiveGold = function () {
    var reward = {
        soft: this.goldCollected
    };
    var event = this.isStateRaid() ? cleverapps.EVENTS.EARN.VILLAGE_RAID : cleverapps.EVENTS.EARN.VILLAGE_ATTACK;
    var rewardList = new RewardsList(reward, { event: event });
    rewardList.receiveRewards();
    rewardList.onAnimationFinished();
};

if (cleverapps.config.debugMode) {
    Village.prototype.attackSomeone = function () {
        var hut = cleverapps.Random.choose(this.huts.filter(function (hut) {
            return hut.canAttack();
        }));

        hut.attack();
    };
}

Village.calculateVillageTotalCost = function (levelNo) {
    if (levelNo < 14) {
        return (3900000 + levelNo * 4500000) / 10;
    }

    levelNo = levelNo + 1;
    var price = -37100000 + 17400000 * levelNo - 1140000 * Math.pow(levelNo, 2) + 35467 * Math.pow(levelNo, 3) - 466 * Math.pow(levelNo, 4) + 2.87 * Math.pow(levelNo, 5) - 0.00434 * Math.pow(levelNo, 6)
    return price / 10;
};

Village.MAX_RAIDS = 3;

Village.STATE_BUILD = 1;
Village.STATE_ATTACK = 2;
Village.STATE_RAID = 3;

Village.GOLD_BY_ATTACK = [
    20000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000,
    65000, 65000, 70000, 70000, 75000, 80000, 82500, 82500, 82500, 85000,
    85000, 85000, 85000, 85000, 87500, 87500, 87500, 87500, 87500, 90000,
    90000, 90000, 90000, 90000, 95000, 95000, 95000, 95000, 95000, 100000,
    100000, 100000, 100000, 100000, 100000, 110000, 110000, 110000, 110000, 120000,
    120000, 120000, 120000, 120000, 120000, 120000, 120000, 120000, 120000, 130000,
    130000, 130000, 130000, 130000, 130000, 130000, 130000, 130000, 130000, 135000,
    135000, 135000, 135000, 135000, 135000, 135000, 135000, 135000, 135000, 135000,
    135000, 135000, 135000, 135000, 140000, 140000, 140000, 140000, 140000, 140000,
    140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000,
    140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000,
    140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000, 140000,
    145000, 145000, 145000, 145000, 145000, 145000, 145000, 145000, 145000, 145000,
    145000, 145000, 145000, 145000, 150000, 150000, 150000, 150000, 150000, 150000,
    150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 360000,
    360000, 360000, 360000, 360000, 400000, 400000, 400000, 400000, 460000, 460000,
    460000, 460000, 460000, 460000, 540000, 540000, 540000, 540000, 540000, 600000,
    600000, 600000, 600000, 600000, 700000, 700000, 700000, 700000, 700000, 800000,
    800000, 800000, 800000, 800000, 900000, 900000, 900000, 900000, 900000, 1000000,
    1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000];

Village.GOLD_BY_DEFEND = [
    5000, 5000, 7500, 10000, 10000, 10000, 10000, 15000, 15000, 20000,
    20000, 20000, 20000, 20000, 20000, 20000, 22500, 22500, 22500, 25000,
    25000, 25000, 25000, 25000, 25000, 25000, 25000, 25000, 25000, 25000,
    27500, 27500, 27500, 27500, 27500, 27500, 27500, 27500, 27500, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
    30000, 30000, 30000, 30000, 35000, 35000, 35000, 35000, 35000, 40000,
    40000, 40000, 40000, 40000, 50000, 50000, 50000, 50000, 50000, 100000,
    100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000,
    100000, 100000, 100000, 100000, 200000, 200000, 200000, 200000, 200000, 200000,
    200000, 200000, 200000, 200000, 200000, 200000, 200000, 200000, 200000, 300000,
    300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000,
    300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000
]; 
