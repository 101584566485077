/**
 * Created by Slava on 15.10.2024
 */

var LevelMasteryRewardView = cc.Node.extend({
    ctor: function (reward) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var icon = this.icon = reward.getIcon();
        var styles = cleverapps.styles.LevelMasteryRewardView;

        this.setContentSize(icon.getContentSize());
        icon.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(icon);

        var text = this.text = cleverapps.UI.generateOnlyText(reward.getText(), cleverapps.styles.FONTS.LEVELMASTERY_REWARD_TEXT);
        text.setPositionRound(styles.ribbon.text);
        this.addChild(text);
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELMASTERY_REWARD_TEXT: {
        size: 50,
        color: new cc.Color(141, 218, 3, 255),
        stroke: {
            color: new cc.Color(5, 124, 25, 255),
            size: 4
        },
    }
});

cleverapps.styles.LevelMasteryRewardView = {
    ribbon: {
        x: { align: "center" },
        y: { align: "center", dy: -30 },
        minWidth: 198,
        height: 101,
        padding: 44,

        text: {
            x: { align: "center" },
            y: { align: "center", dy: -40 }
        }
    }
};