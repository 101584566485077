/**
 * Created by Mikhail Menshenin on 29.08.2024
 */

var RaidsView = cc.Node.extend({
    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        var styles = cleverapps.styles.RaidsView;
        this.village = village;
        this.shovels = [];
        for (var index = 0; index < Village.MAX_RAIDS; index++) {
            var shovel = new cc.Sprite(bundles.village.frames.shovel_png);
            this.shovels.push(shovel);
        }
        var layout = new cleverapps.Layout(this.shovels, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this.setContentSize2(layout.getContentSize());
        layout.setPosition(this.width / 2, this.height / 2);
        this.addChild(layout);

        this.updateShovels(village);
        village.on("changeRaids", this.createListener(this.updateShovels.bind(this, village)));

        this.show();
    },

    updateShovels: function (village) {
        this.shovels.forEach(function (shovel, index) {
            if ((shovel.visible !== (index < village.raids)) && shovel.visible) {
                shovel.runAction(
                    new cc.Sequence(
                        new cc.ScaleTo(0.2, 1.3).easing(cc.easeBackIn()),
                        new cc.ScaleTo(0.15, 0),
                        new cc.Hide()
                    )
                );
            } else {
                shovel.setVisible(index < village.raids);
            }
        });
    },

    show: function () {
        var village = this.village;
        this.shovels.forEach(function (shovel, index) {
            if (index < village.raids) {
                shovel.setScale(0.1);
                shovel.runAction(new cc.Sequence(
                    new cc.Hide(),
                    new cc.DelayTime(2.2 + 0.2 * index),
                    new cc.Show(),
                    cleverapps.UI.animateScale({
                        duration: 0.5,
                        scale: 1,
                        overScaling: 1.5
                    })
                ));
            }
        });
    }
});

cleverapps.styles.RaidsView = {
    margin: 10
};