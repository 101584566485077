/**
 * Created by vladislav on 18.03.2021
 */

var SlotMachineReelView = ccui.Layout.extend({
    ctor: function (reel) {
        this._super();

        this.reel = reel;

        this.setClippingEnabled(true);

        var styles = cleverapps.styles.SlotMachineReelView;

        this.setContentSize2(styles);

        this.reel.symbols.forEach(function (symbol) {
            this.addChild(new SlotMachineSymbolView(symbol));
        }.bind(this));
    }
});

cleverapps.styles.SlotMachineReelView = {
    width: 140,
    height: 486
};