/**
 * Created by Mikhail Menshenin on 20.09.2024
 */

cleverapps.overrideStyles(cleverapps.styles.HutView, {
    villages: {
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrossView, {
    villages: {
    }
});