/**
 * Created by Mikhail Menshenin on 09.08.2024
 */

cleverapps.Lives.prototype.getMaxLives = function () {
    return 50;
};

cleverapps.Lives.prototype.canShowForce = function () {
    return false;
}