/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var SlotMachineView = cc.Node.extend({
    ctor: function (slotMachine) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.slotMachine = slotMachine;

        var styles = cleverapps.styles.SlotMachineView;

        var reelsBg = new cc.Sprite(bundles.slotmachine.frames.inner_part_png);
        reelsBg.setPositionRound(styles.reelsBg);
        this.addChild(reelsBg);

        var reelViews = this.slotMachine.reels.map(function (reel) {
            return new SlotMachineReelView(reel);
        }, this);
        var reelsLayout = new cleverapps.Layout(reelViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.reels.margin
        });
        reelsLayout.setPositionRound(styles.reels);
        this.addChild(reelsLayout);

        var reelsFg = new cc.Sprite(bundles.slotmachine.frames.slot_machine_png);
        reelsFg.setPositionRound(styles.reelsFg);
        this.addChild(reelsFg);
        this.setContentSize2(reelsFg.getContentSize());

        var energyProgresBar = new SlotMachineEnergyView(slotMachine);
        energyProgresBar.setPositionRound(styles.progress);
        this.addChild(energyProgresBar);

        var bg = cleverapps.UI.createScale9Sprite(bundles.slotmachine.frames.message_bg);
        bg.setPositionRound(styles.messageBg);
        bg.setContentSize2(styles.messageBg);
        this.addChild(bg);

        var message = this.message = new TextWithIcon(this.getMessageString(), {
            font: cleverapps.styles.FONTS.SLOTMACHINE_MESSAGE_TEXT,
            margin: 10,
            icons: {
                "@@": bundles.slotmachine.frames.coin_icon_big_png,
                "##": bundles.slotmachine.frames.message_icon
            },
            iconScale: 0.65
        });
        message.setPositionRound(styles.message);
        bg.addChild(message);

        var coinMaster = this.coinMaster = new CoinMasterView();
        coinMaster.setPositionRound(styles.coinmaster);
        this.addChild(coinMaster);

        this.slotMachine.on("message", this.createListener(this.showMessage.bind(this)));
        this.slotMachine.on("spin", this.createListener(this.hideMessage.bind(this)));
        this.slotMachine.on("spin", this.createListener(this.animateSpin.bind(this)));
        this.slotMachine.on("coinsRecieve", this.createListener(this.animateCoinsRecieve.bind(this)));
        this.slotMachine.on("iconsRecieve", this.createListener(this.animateIconsRecieve.bind(this)));
    },

    showMessage: function (message, cb) {
        this.message.setString(this.getMessageString());
        this.message.runAction(
            new cc.Sequence(
                new cc.Show(),
                new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()),
                new cc.CallFunc(cb || function () { })
            )
        );
    },

    hideMessage: function () {
        this.message.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
                new cc.Hide()
            )
        );
    },

    getMessageString: function () {
        return this.slotMachine.message || "click spin ##";
    },

    animateSpin: function () {
        cleverapps.audio.playSound(bundles.game.urls.slot_spin, { throttle: 250 });
    },

    animateCoinsRecieve: function (animation, cb) {
        var scene = cleverapps.scenes.getRunningScene();
        var spine = new cleverapps.Spine(bundles.slotmachine.jsons.reward_gold_json);
        spine.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(spine);
        spine.setAnimation(0, animation || "animation", false);
        spine.setCompleteListenerRemove(cb || function () { });
        spine.replaceParentSamePlace(scene.movingNode);
    },

    animateIconsRecieve: function (options, cb) {
        var animation = options.icon_animation || options.animation || "animation";
        var styles = cleverapps.styles.SlotMachineView;
        var scene = cleverapps.scenes.getRunningScene();
        var spine = new cleverapps.Spine(options.spine || bundles.slotmachine.jsons.reward_icons_json);
        cb = {
            "shield_reward": this.animateShieldRecieve.bind(this, spine, cb)
        }[animation] || cb || function () { };
        spine.setPositionRound(styles.icon_animation);
        this.addChild(spine);
        spine.setAnimation(0, animation || "animation", false);
        if (animation === "shield_reward") {
            spine.setCompleteListener(cb);
        } else {
            spine.setCompleteListenerRemove(cb);
        }
        spine.replaceParentSamePlace(scene.movingNode);
    },

    animateShieldRecieve: function (flyIcon, cb) {
        var shield = cleverapps.shields.get();
        if (cleverapps.shields.exceeded) {
            shield = 2;
        }
        flyIcon.runAction(new cc.Sequence(
            AnimationsLibrary.animateCollect(flyIcon, "shield" + shield, {
                duration: 0.3,
                scale: 0.5 * cleverapps.styles.ShieldsView.scale[cleverapps.resolution.mode]
            }),
            new cc.DelayTime(0.1),
            new cc.CallFunc(cb || function () { }),
            new cc.RemoveSelf()
        ));

        if (cleverapps.shields.get() === Shields.MAX_SHIELDS) {
            cleverapps.audio.playSound(bundles.game.urls.slot_shield_collect_end);
        } else {
            cleverapps.audio.playSound(bundles.game.urls.slot_shield_collect);
        }
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SLOTMACHINE_MESSAGE_TEXT: {
        name: "default",
        size: 45,
        color: new cc.Color(159, 29, 5),
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
});

cleverapps.styles.SlotMachineView = {
    reelsBg: {
        x: { align: "center" },
        y: { align: "center", dy: -65 }
    },

    reels: {
        margin: 75,

        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -65 }
    },

    reelsFg: {
        x: { align: "center" },
        y: { align: "center" }
    },

    progress: {
        x: { align: "center" },
        y: { align: "bottom", dy: 125 }
    },

    messageBg: {
        x: { align: "center" },
        y: { align: "center", dy: 230 },
        width: 440,
        height: 85
    },

    message: {
        x: { align: "center" },
        y: { align: "center", dy: -2 }
    },

    coinmaster: {
        x: { align: "center", dx: -11 },
        y: { align: "top", dy: -32 }
    },

    icon_animation: {
        x: { align: "center", dx: 2 },
        y: { align: "center", dy: 20 }
    },

    shield: {
        scale: 0.58,
        x: { align: "center", dx: -1 },
        y: { align: "center", dy: 10 }
    }
};