/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

var VillageAttackScene = VillageActionScene.extend({
    ctor: function (options) {
        this._super();

        this.village.prepareToAttack(options);
    },

    getVillage: function () {
        return aisensia.villageManager.getAttackVillage();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_VILLAGE_ATTACK);

        cleverapps.focusManager.showControlsTemporary("village");
    },

    addUI: function () {
        var styles = cleverapps.styles.VillageAttackScene;

        this.addControlPanel(styles);
    },

    addControlPanel: function (styles) {
        var controlPanel = new AttackControlPanelView(this.village);
        controlPanel.setPositionRound(styles.control);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.UI.fitToBox(controlPanel, styles.control);
        }
        this.addChild(controlPanel);
        controlPanel.show();
    },

    handleFinishEvent: function (f) {
        this.village.counter.registerStage(10, function () {
            if (this.village.isAttacked()) {
                this.showResultPanel(f);
            }
        }.bind(this));
    },

    showResultPanel: function (f) {
        var styles = cleverapps.styles.VillageAttackScene;
        var resultPanel = new AttackResultPanelView(this.village);
        resultPanel.setPositionRound(styles.result.position);
        resultPanel.setScale2(styles.result.scale);
        this.addChild(resultPanel);
        resultPanel.show();

        this.hammer.runAction(new cc.Sequence(
            new cc.MoveBy(0.3, 0, -this.hammer.height * 1.5).easing(cc.easeIn(1)),
            new cc.Hide()
        ));

        var label = new TapToContinue({
            text: "Window.TapToContinue"
        });
        label.setPositionRound(styles.tapToContinue);
        this.addChild(label);
        label.show();

        cleverapps.UI.onClick(this, function () {
            f();
        }, {
            interactiveScale: false
        });
    }
});

cleverapps.styles.VillageAttackScene = {
    hammer: {
        x: { align: "center", dx: -47 },
        y: { align: "bottom", dy: -8 }
    },

    control: {
        width: 520,
        height: 160,
        x: { align: "center", dx: 3 },
        y: { align: "top", dy: 35 }
    },

    result: {
        scale: [0.6, 1, 1],
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: -20 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -40 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -40 }
        }],
    },

    tapToContinue: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 250 }
    }
};

cleverapps.Environment.SCENE_VILLAGE_ATTACK = "villageAttack";