/**
 * Created by Mikhail Menshenin on 01.11.2024
 */

ShopProductSource.listProducts = cleverapps.extendFunc(ShopProductSource.listProducts, function (tab) {
    var village = aisensia.villageManager.getVillage();
    var cost = village.cost;

    if (cost) {
        VirtualProducts.softCurrencyVerySmall.reward.soft = Math.round(cost * 0.04 / 1000) * 1000;
        VirtualProducts.softCurrencySmall.reward.soft = Math.round(cost * 0.125 / 1000) * 1000;
        VirtualProducts.softCurrencyMedium.reward.soft = Math.round(cost * 0.30 / 1000) * 1000;
        VirtualProducts.softCurrencyLarge.reward.soft = Math.round(cost * 0.70 / 1000) * 1000;
    }

    return this._super(tab);
});