/**
 * Created by Mikhail Menshenin on 23.08.2024
 */

var VillageRaidScene = VillageActionScene.extend({
    ctor: function (options) {
        this._super();

        this.village.prepareToRaid(options);

        cleverapps.audio.playSound(bundles.game.urls.slot_next_scene);
    },

    getVillage: function () {
        return aisensia.villageManager.getRaidVillage();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_VILLAGE_RAID);

        this.village.on("animateGoldRaided", this.createListener(this.animateGoldRaided.bind(this)));
    },

    addUI: function () {
        var styles = cleverapps.styles.VillageRaidScene;
        this.addControlPanel(styles);
        this.addRaidsView(styles);
    },

    addControlPanel: function (styles) {
        var controlPanel = this.controlPanel = new RaidControlPanelView(this.village);
        controlPanel.setPositionRound(styles.control);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.UI.fitToBox(controlPanel, styles.control);
        }
        this.addChild(controlPanel);
        controlPanel.showRaidScene();
    },

    addStealPanel: function () {
        var styles = cleverapps.styles.VillageRaidScene;
        var stolePanel = this.stolePanel = new RaidStealPanelView(this.village);
        stolePanel.setPositionRound(styles.steal);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.UI.fitToBox(stolePanel, styles.steal);
        }
        this.addChild(stolePanel, 5);
        stolePanel.setVisible(false);
    },

    animateGoldRaided: function (gold, cb) {
        var styles = cleverapps.styles.VillageRaidScene;
        var goldRaidedText;

        if (gold === 0) {
            cleverapps.audio.playSound(bundles.main.urls.nocoins);
            cleverapps.exclamation.show("no coins", Exclamation.Types.No_firework, cb);
        } else {
            cleverapps.audio.playSound(bundles.main.urls.winnings);
            goldRaidedText = cleverapps.UI.generateOnlyText(aisensia.getNumberWithCommas(gold), cleverapps.styles.FONTS.RAID_REWARD_TEXT);
            goldRaidedText.setPositionRound(styles.raidedText);
            this.addChild(goldRaidedText);
            goldRaidedText.setScale(0);
            var goldTextBg = new cc.Sprite(bundles.village.frames.numbers_bg);
            goldTextBg.setPositionRound(styles.raidedText.bg);
            cleverapps.UI.fitToBox(goldTextBg, styles.raidedText.bg);
            goldRaidedText.addChild(goldTextBg, -1);
            goldRaidedText.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 1),
                new cc.DelayTime(0.4),
                new cc.Spawn(
                    AnimationsLibrary.animateCollect(goldRaidedText, "earnedText", {
                        duration: 0.2,
                        adjustToTarget: true,
                        jump: true
                    }),
                    new cc.CallFunc(function () {
                        this.village.trigger("animateGoldHighlight");
                    }.bind(this))
                ),
                new cc.Hide(),
                new cc.CallFunc(function () {
                    this.village.trigger("changeGoldRaided", gold, cb);
                }.bind(this))
            ));
        }
    },

    addRaidsView: function (styles) {
        var raidsView = new RaidsView(this.village);
        raidsView.setPositionRound(styles.raids.position);
        raidsView.setScale(styles.raids.scale[cleverapps.resolution.mode]);
        this.addChild(raidsView);
    },

    handleFinishEvent: function (f) {
        this.village.counter.registerStage(10, function () {
            if (this.village.isRaidFinished()) {
                this.addStealPanel();

                this.controlPanel.runAction(new cc.Sequence(
                    new cc.Sequence(
                        new cc.CallFunc(function () {
                            if (this.village.perfectRaid) {
                                cleverapps.audio.playSound(bundles.main.urls.perfect);
                                cleverapps.exclamation.show("Perfect Raid", Exclamation.Types.Firework, undefined);
                            }
                        }.bind(this)),
                        new cc.DelayTime(this.village.perfectRaid ? 0.5 : 0),
                        new cc.Spawn(
                            new cc.CallFunc(function () {
                                this.controlPanel.hide();
                            }.bind(this)),
                            new cc.CallFunc(function () {
                                this.stolePanel.show();

                                var button = new TapToContinue({
                                    text: "Window.TapToContinue"
                                });
                                button.setPositionRound(cleverapps.styles.VillageRaidScene.tapToContinue);
                                this.addChild(button, 5);
                                button.show();

                                cleverapps.UI.onClick(this, function () {
                                    f();
                                }, {
                                    interactiveScale: false
                                });
                            }.bind(this))
                        )
                    )
                ));
            }
        }.bind(this));
    }
});

cleverapps.Environment.SCENE_VILLAGE_RAID = "villageRaid";

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RAID_REWARD_TEXT: {
        size: 100,
        color: new cc.Color(254, 187, 15, 255),
        stroke: {
            color: new cc.Color(139, 56, 2, 255),
            size: 3
        }
    },
    RAID_NO_REWARD_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            color: new cc.Color(139, 56, 2, 255),
            size: 2
        },
    },
    RAID_EXCLAMATION_TEXT: {
        size: 110,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            color: new cc.Color(80, 192, 153, 255),
            size: 3
        }
    }
});

cleverapps.styles.VillageRaidScene = {
    control: {
        width: 540,
        height: 260,

        x: { align: "center", dx: -3 },
        y: { align: "top", dy: 40 }
    },

    steal: {
        width: 540,
        height: 260,

        x: { align: "center", dx: -3 },
        y: { align: "bottom", dy: -60 }
    },

    raids: {
        scale: [0.9, 1.15, 1.15],
        position: [{
            x: { align: "left", dx: 56 },
            y: { align: "bottom", dy: 40 }
        }, {
            x: { align: "left", dx: 78 },
            y: { align: "bottom", dy: 66 }
        }, {
            x: { align: "left", dx: 78 },
            y: { align: "bottom", dy: 66 }
        }]
    },

    raidedText: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },
        bg: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            widht: 350,
            height: 250
        }
    },

    perfectRaidRibbon: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -100 }
    },

    tapToContinue: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 250 }
    }
};
