/**
 * Created by Slava on 14.10.2024
 */

LevelMasteryWindow.prototype.createContent = function (styles) {
    var content = this.content = new cc.Node();
    content.setContentSize2(styles);

    var bg = new cc.Sprite(bundles.levelmasterywindow.frames.window_purple_background_png);
    bg.setPositionRound(styles.bg);
    content.addChild(bg);
    cleverapps.UI.fitToBox(bg, styles.bg);

    var dragon = new cc.Sprite(bundles.levelmasterywindow.frames.dragon);
    dragon.setPositionRound(styles.dragon);
    this.addChild(dragon, 10);
    cleverapps.UI.fitToBox(dragon, styles.dragon);

    var gridScroll = this.gridScroll = this.createGridScroll(styles.grid);
    gridScroll.setPositionRound(styles.grid);
    content.addChild(gridScroll);

    var fg = new cc.Sprite(bundles.levelmasterywindow.frames.window_purple_foreground_png);
    fg.setPositionRound(styles.fg);
    content.addChild(fg);
    cleverapps.UI.fitToBox(fg, styles.fg);

    var grandPrize = this.createGrandPrize(styles);
    grandPrize.setPositionRound(styles.grandPrizeBg);
    content.addChild(grandPrize);

    var progressBar = this.progressBar = new LevelMasteryView();
    progressBar.setPositionRound(styles.progressBar);
    content.addChild(progressBar);

    var timer = this.createTimer(styles.timer);
    content.addChild(timer);

    return content;
};

LevelMasteryWindow.prototype.createGrandPrize = function (styles) {
    var grandPrizeBg = new cc.Sprite(bundles.levelmasterywindow.frames.grandprize_backing);
    cleverapps.UI.fitToBox(grandPrizeBg, styles.grandPrizeBg);

    var grandText = cleverapps.UI.generateOnlyText("LevelMasteryWindow.GrandPrize", cleverapps.styles.FONTS.LEVELMASTERY_GRAND_PRIZE_TEXT);
    grandText.setPositionRound(styles.grandPrizeBg.text);
    grandPrizeBg.addChild(grandText);

    var icons = [bundles.slotmachine.frames.energy_png, bundles.slotmachine.frames.stack_png, bundles.slotmachine.frames.raid_png];
    var count = [1525000, 2700000, 700000];
    var items = [];
    var index = 0;

    for (var i = 0; i < icons.length; i++) {
        items[index] = new cc.Sprite(icons[i]);

        var text = cleverapps.UI.generateOnlyText(count[i], cleverapps.styles.FONTS.LEVELMASTERY_REWARDS_TEXT);
        text.setPositionRound(styles.grandPrizeBg.rewards.text);
        items[index].addChild(text);

        index++;
        if (i < icons.length - 1) {
            items[index] = cleverapps.UI.generateOnlyText("+", cleverapps.styles.FONTS.LEVELMASTERY_PLUS);
            index++;
        }
    }

    var layout = new cleverapps.Layout(items, {
        direction: cleverapps.UI.HORIZONTAL,
        margin: styles.grandPrizeBg.rewards.margin
    });
    layout.setPositionRound(styles.grandPrizeBg.rewards);
    grandPrizeBg.addChild(layout);

    return grandPrizeBg;
};

LevelMasteryWindow.prototype.onWindowLoaded = cleverapps.extendFunc(LevelMasteryWindow.prototype.onWindowLoaded, function (mission) {
    this._super(mission);

    var styles = cleverapps.styles.LevelMasteryWindow;
    this.windowTitle.type.position.x.dx = styles.title.dx;
});

LevelMasteryWindow.prototype.createRewardCard = function (styles, reward, stage) {
    var isCompleted = aisensia.levelMastery.isCompleted(stage);
    var isCurrent = aisensia.levelMastery.isCurrent(stage);
    var isLocked = !isCurrent && !isCompleted;

    var card = cleverapps.UI.createScale9Sprite(isCompleted ? bundles.levelmastery.frames.card_bg_white_png : bundles.levelmastery.frames.card_bg_png);
    card.setAnchorPoint(0.5, 0.5);
    card.setContentSize(styles);

    if (isCurrent) {
        var rays = new cc.Sprite(bundles.levelmastery.frames.card_rays_png);
        rays.setPositionRound(styles.rays);
        cleverapps.UI.fitToBox(rays, styles.rays);
        card.addChild(rays);
    }

    var icon = isCompleted ? new cc.Sprite(bundles.levelmastery.frames.card_mark_png) : new LevelMasteryRewardView(reward);
    icon.setPositionRound(styles.icon);
    cleverapps.UI.fitToBox(icon, styles.icon);
    card.addChild(icon);

    if (isLocked) {
        var lock = new cc.Sprite(bundles.levelmastery.frames.card_lock_png);
        lock.setPositionRound(styles.lock);
        card.addChild(lock);
    }

    return card;
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELMASTERY_GRAND_PRIZE_TEXT: {
        size: 47,
        color: cleverapps.styles.COLORS.WHITE,
        name: "nostroke"
    },

    LEVELMASTERY_REWARDS_TEXT: {
        size: 35,
        color: new cc.Color(141, 218, 3, 255),
        stroke: {
            color: new cc.Color(5, 124, 25, 255),
            size: 3
        },
    },

    LEVELMASTERY_PLUS: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_WHITE_TEXT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelMasteryWindow, {
    width: 700 + 28,
    height: 1044 + 28,

    CloseButton: {
        x: { align: "right", dx: -36 },
        y: { align: "top", dy: -26 }
    },

    fg: {
        x: { align: "center" },
        y: { align: "top", dy: 60 },
        width: 700,
        height: 2000
    },

    bg: {
        x: { align: "center" },
        y: { align: "bottom", dy: -212 },
        width: 700,
        height: 2000
    },

    progressBar: {
        x: { align: "center", dx: -13 },
        y: { align: "top", dy: -250 },
        width: 610,
        height: 200
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: -25 },
        background: {
            frame: bundles.sidebar.frames.icon_text_bg,
            width: 280
        }
    },

    dragon: {
        x: { align: "center", dx: -265 },
        y: { align: "center", dy: 440 },
        width: 220,
        height: 280
    },

    title: {
        dx: 40
    },

    grandPrizeBg: {
        x: { align: "center", dx: 60 },
        y: { align: "top", dy: -90 },
        width: 430,
        height: 1610,

        text: {
            x: { align: "center" },
            y: { align: "bottom", dy: 15 },
        },

        rewards: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 40 },
            margin: 35,
            text: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -50 },
            },
        }
    },

    grid: {
        x: { align: "center" },
        y: { align: "top", dy: -300 },
        width: 800,
        height: 760,

        margin: 20,
        padding: {
            top: 60,
            bottom: 60
        },

        row: {
            margin: 140,
            padding: {
                left: 50,
                right: 0
            },

            card: {
                width: 330,
                height: 155,

                rays: {
                    x: { align: "center" },
                    y: { align: "center", dy: 3 },
                    width: 290,
                    height: 120
                },

                icon: {
                    x: { align: "center" },
                    y: { align: "center" },
                    width: 330 - 60,
                    height: 178 - 80
                },

                lock: {
                    x: { align: "right", dx: 0 },
                    y: { align: "bottom", dy: -10 }
                }
            }
        }
    },
});