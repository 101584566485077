/**
 * Created by Slava on 05.09.2024
 */

cleverapps.reloadSnapshotScene = function (scene, f) {
    if (scene.villageState === Village.STATE_BUILD) {
        cleverapps.travelBook.gotoMainScene(f);
        return;
    }

    if (scene.villageState === Village.STATE_ATTACK) {
        cleverapps.focusManager.displayWhenFreeFocus({
            focus: "VillageAttackScene",
            control: [],
            action: function (f) {
                var scene = new VillageAttackScene(aisensia.savedVillage);
                cleverapps.scenes.replaceScene(scene, f);
            }
        });
        f();
        return;
    }
    if (scene.villageState === Village.STATE_RAID) {
        cleverapps.focusManager.displayWhenFreeFocus({
            focus: "VillageRaidScene",
            control: [],
            action: function (f) {
                var scene = new VillageRaidScene(aisensia.savedVillage);
                cleverapps.scenes.replaceScene(scene, f);
            }
        });
        f();
        return;
    }

    var level = new Level(scene.episodeNo, scene.levelNo);
    var page = cleverapps.travelBook.getPageById(level.meta.expedition) || cleverapps.travelBook.getPageById("main");
    cleverapps.travelBook.setCurrentPage(page);
    level.play(f);
};
