/**
 * Created by Mikhail Menshenin on 10.09.2024
 */

MethaHelper.getCurrentLevel = function () {
    return MethaHelper.getLevel(0, 0);
};

cleverapps.isLevels = function () {
    return false;
};
